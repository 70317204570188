<template>
  <section class="poll__sectionAnswersContainer">
    <div class="poll__sectionAnswersMain custom__scroll">
      <div v-for="option in questionSelected.options" :key="option._id" class="poll__sectionAnswers" @click="selectAnswer(option)">
        <img :src="option.img" class="poll__sectionAnswersImg" alt="" />
        <span class="poll__sectionAnswersTitle">{{ option.text }}</span>
        <div
          @click="deleteCheck({ option: option._id, question: questionSelected._id })"
          v-if="optionsSelected(option, questionSelected)"
          class="poll__checked"
        >
          <iconic class="poll__checkedIcon" name="check"></iconic>
        </div>
      </div>
    </div>
    <AnswerSelected :option="answerSelectedToShow" :question="questionSelected" @vote="vote" @deleteCheck="deleteCheck" :hasVotes="votes" />
  </section>
</template>
<script>
import AnswerSelected from "./AnswerSelected.vue";
export default {
  props: ["vote", "votes", "optionsSelected", "answerSelectedToShow", "questionSelected", "selectAnswer"],
  components: {
    AnswerSelected,
  },
  methods: {
    deleteCheck({ option, question }) {
      this.$emit("deleteCheck", { option, question });
    },
  },
};
</script>
<style lang="scss">
.poll {
  @media (min-width: 769px) {
    &__sectionAnswersContainer {
      display: grid;
      grid-template-columns: 1fr 45%;
      gap: 20px;
      width: 70vw;
      max-width: 90vw;
    }
    &__sectionAnswersMain {
      min-height: 100%;
      margin-top: 0;
      padding-right: 0;
    }

    &__sectionAnswersMain {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
